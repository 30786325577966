import React from 'react';

import { Layout } from '../components/layout';
import SEO from '../components/seo';

import asanaLogo from '../images/logos/asana.svg';
import dkbLogo from '../images/logos/dkb.svg';
import eyeemLogo from '../images/logos/eyeem.svg';
import goodjobsLogo from '../images/logos/goodjobs.svg';
import krasserstoffLogo from '../images/logos/krasserstoff.svg';
import mizLogo from '../images/logos/miz.svg';
import recordsaleLogo from '../images/logos/recordsale.svg';
import telekomLogo from '../images/logos/telekom.svg';

import ideaIcon from '../images/icons/idea.svg';
import executionIcon from '../images/icons/execution.svg';
import consultingIcon from '../images/icons/consulting.svg';

import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

declare const google: any; // eslint-disable-line

export const pageQuery = graphql`
  query Home {
    asanaTitleImage: file(relativePath: { eq: "photos/asana-title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    asanaDetailImage: file(relativePath: { eq: "photos/asana-detail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ksTitleImage: file(relativePath: { eq: "photos/ks-title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ksDetailImage: file(relativePath: { eq: "photos/ks-detail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office1Image: file(relativePath: { eq: "photos/office1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office2Image: file(relativePath: { eq: "photos/office2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office3Image: file(relativePath: { eq: "photos/office3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office4Image: file(relativePath: { eq: "photos/office4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 536) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

interface Image {
  childImageSharp: {
    fluid: FluidObject;
  };
}

interface Props {
  data: {
    asanaTitleImage: Image;
    asanaDetailImage: Image;
    ksTitleImage: Image;
    ksDetailImage: Image;
    office1Image: Image;
    office2Image: Image;
    office3Image: Image;
    office4Image: Image;
  };
}

export default function Home({ data }: Props): JSX.Element {
  const {
    asanaTitleImage,
    asanaDetailImage,
    ksTitleImage,
    ksDetailImage,
    office1Image,
    office2Image,
    office3Image,
    office4Image,
  } = data;
  return (
    <Layout>
      <Helmet>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjYPsyFD-m9xYBw1yFUat-TAQobr0hzlI"></script>
      </Helmet>
      <SEO title="" />
      <section className="intro">
        <div className="container">
          <div className="intro__icon">👋</div>
          <h1 className="intro__lead">
            We're a product development studio, busy designing and building
            tomorrow's apps.
          </h1>
        </div>
      </section>
      <section className={`work`}>
        <div className="container spacer">
          <div className="work__project work__first-project">
            <div className="work__details">
              <div className="work__category">Client Work / App API</div>
              <h2 className="work__title">Asana Rebel</h2>
              <p className="work__description">
                We extended the backend capabilities of the app
                <a
                  className="work__link"
                  href="https://asanarebel.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit their page
                </a>
              </p>
            </div>
            <div className="work__image">
              <Img
                fluid={asanaTitleImage.childImageSharp.fluid}
                alt="Asana Rebel"
              />
              <Img
                fluid={asanaDetailImage.childImageSharp.fluid}
                alt="Asana Rebel"
              />
            </div>
          </div>
          <div className="work__project work__second-project">
            <div className="work__details">
              <div className="work__category">Client Work / B2B Backend</div>
              <h2 className="work__title">Krasser Stoff</h2>
              <p className="work__description">
                We reworked the B2B Backend for their ticket retail platform
                <a
                  className="work__link"
                  href="https://krasserstoff.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit their page
                </a>
              </p>
            </div>
            <div className="work__image">
              <Img
                fluid={ksTitleImage.childImageSharp.fluid}
                alt="Krasser Stoff"
              />
              <Img
                fluid={ksDetailImage.childImageSharp.fluid}
                alt="Krasser Stoff"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="customers spacer">
        <div className="logo-wall">
          <div className="container">
            <h2>We worked with</h2>
            <div className="logo-wall__logos">
              <img alt="Telekom" src={telekomLogo} />
              <img alt="DKB" src={dkbLogo} />
              <img alt="EyeEm" src={eyeemLogo} />
              <img alt="Asana Rebel" src={asanaLogo} />
              <img alt="Krasser Stoff" src={krasserstoffLogo} />
              <img alt="GoodJobs" src={goodjobsLogo} />
              <img alt="MIZ" src={mizLogo} />
              <img alt="recordsale" src={recordsaleLogo} />
              <div className="logo-wall__placeholder">
                &amp; 32 more companies
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services" id="services">
        <div className="container">
          <h2>Our Services</h2>
          <p className="big">
            We help you launch your product from the very first idea all the way
            to the App Store or Web. We work in a collaborative and lean way so
            you don't have to worry about hidden costs or expensive
            presentations.
          </p>
        </div>
        <div className="container container--wide spacer">
          <div className="service-chart">
            <article className="service-chart__service">
              <img
                className="service-chart__service-image"
                alt="Ideation"
                src={ideaIcon}
              />
              <h3 className="service-chart__service-title">
                Ideation &amp; <br />
                Prototyping
              </h3>
              <p className="small service-chart__service-description">
                We help you figure out the <em>right</em> solution for your
                business problem. During that process we build prototypes and
                test them with real people quickly. So no guessing and
                overthinking.
              </p>
            </article>
            <article className="service-chart__service">
              <img
                className="service-chart__service-image"
                alt="Execution"
                src={executionIcon}
              />
              <h3 className="service-chart__service-title">
                Development &amp; <br />
                Maintainance
              </h3>
              <p className="small service-chart__service-description">
                Once we figured out <em>what</em> to build - we help you bring
                your product to life by building a production ready
                application. We’re also happy to continue working on your
                project, scale it and create new features.
              </p>
            </article>
            <article className="service-chart__service">
              <img
                className="service-chart__service-image"
                alt="Consulting"
                src={consultingIcon}
              />
              <h3 className="service-chart__service-title">
                Consulting &amp; <br />
                Workshops
              </h3>
              <p className="small service-chart__service-description">
                You already got an awesome team working on your product? No
                problem. We’re happy to collaborate. Also we can coach your team
                regarding specific technologies or product design concepts.
              </p>
            </article>
          </div>
        </div>
      </section>
      <div className="container spacer">
        <h2>The Nerdtalk</h2>
        <p className="big">
          Wise developers always say: <em>The best code is no code at all</em>.
          But if we do write code we use some of these technologies
        </p>
        <div className="feature-list">
          <div className="feature-list__column feature-list__column--web">
            <h4>Web</h4>
            <ul>
              <li>React</li>
              <li>Webpack</li>
              <li>Parcel</li>
              <li>TypeScript</li>
              <li>SCSS</li>
            </ul>
          </div>
          <div className="feature-list__column feature-list__column--native">
            <h4>Native</h4>
            <ul>
              <li>iOS</li>
              <li>Android</li>
              <li>React Native</li>
              <li>Swift / Objective C</li>
              <li>Kotlin / Java</li>
            </ul>
          </div>
          <div className="feature-list__column feature-list__column--backend">
            <h4>Backend</h4>
            <ul>
              <li>Ruby on Rails</li>
              <li>node.js</li>
              <li>Swift</li>
              <li>PostgreSQL</li>
              <li>ElasticSearch</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="jobs spacer" id="jobs">
        <div className="container">
          <h2>Jobs</h2>
          <div className="job-list">
            <a className="job-list__job" href="/jobs/product-designer">
              <h4 className="job-list__job-title">Product Designer</h4>
              <label className="job-list__job-detail">Fulltime, Remote</label>
            </a>
            <a className="job-list__job" href="/jobs/full-stack-developer">
              <h4 className="job-list__job-title">Full Stack Developer</h4>
              <label className="job-list__job-detail">Fulltime, Remote</label>
            </a>
            <a className="job-list__job" href="/jobs/frontend-developer">
              <h4 className="job-list__job-title">Front-end Developer</h4>
              <label className="job-list__job-detail">Fulltime, Remote</label>
            </a>
          </div>
          <p className="big">
            You're interested in how we work?
            <br /> Have a look at our{' '}
            <a className="link" href="/handbook">
              Company Handbook
            </a>
            .
          </p>
        </div>
      </section>

      <section className="office" id="office">
        <div className="container">
          <h2>Office</h2>
          <p className="big">
            Some snapshots of our office. By the way - we have some open desks.
            If you're interested in hanging out with us,{' '}
            <a className="link" href="/#contact">
              contact us
            </a>
            .
          </p>
        </div>
        <div className="container container--wide spacer">
          <div className="mosaik">
            <Img fluid={office1Image.childImageSharp.fluid} alt="Our Office" />
            <Img fluid={office2Image.childImageSharp.fluid} alt="Our Office" />
            <Img fluid={office3Image.childImageSharp.fluid} alt="Our Office" />
            <Img fluid={office4Image.childImageSharp.fluid} alt="Our Office" />
          </div>
        </div>
      </section>
      <section className="contact" id="contact">
        <div className="container">
          <h2>Contact</h2>
          <div className="contact-card">
            <div className="contact-card__address">
              <div>
                <strong>nerdgeschoss GmbH</strong>
              </div>
              <div>Kottbusser Damm 72</div>
              <div>10967 Berlin</div>
              <p />
              <div>T. 030 364 28 658</div>
              <div>M. hello@nerdgeschoss.de</div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
